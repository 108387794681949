import React, { ReactElement, ReactNode, useCallback, useMemo, useRef, useState } from 'react';
import { css, SerializedStyles } from '@emotion/react';
import uniqueId from 'lodash/uniqueId';
import * as Styled from './styles';
import { ButtonType, FeatureType } from './types';
import pricingFeatureDivider from '../../assets/pricing-feature-divider.svg';
import crown from '../../assets/icon-crown.svg';
import { useVisibilityDetection } from '../../hooks/useVisibilityDetection';

type Price = 'lifetime' | 'monthly';

interface PriceData {
  name: string | ReactNode;
  price: Record<
    Price,
    {
      amount: number;
      url: string;
    }
  >;
  buttonType: ButtonType;
  featuresStyles: SerializedStyles;
  priceType: string;
  features: {
    type: FeatureType;
    description: string | ReactNode;
  }[];
}

const DATA: PriceData[] = [
  {
    name: 'Subscription',
    buttonType: 'outline',
    price: {
      monthly: {
        amount: 39,
        url: 'https://checkout.sketchwow.com?add-to-cart=816352&swp=home',
      },
      annual: {
        amount: 20,
        url: 'https://checkout.sketchwow.com?add-to-cart=816365&swp=home',
      },
    },
    featuresStyles: css`
      margin-top: 50px;
      @media screen and (max-width: 1020px) {
        margin-top: 35px;
      }
    `,
    priceType:"monthly",
    features: [
      // {
      //   type: 'contains',
      //   description: 'Up to 40 elements per document',
      // },
      {
        type: 'contains',
        description: 'Up to 10 pages per document',
      },
      {
        type: 'contains',
        description: 'Unlimited sketches',
      },
      {
        type: 'contains',
        description: 'AI-powered mindmaps, flows, visuals',
      },
      {
        type: 'contains',
        description: '73 templates',
      },
      {
        type: 'contains',
        description: '350 elements, shapes, icons',
      },
      {
        type: 'contains',
        description: 'Standard color backgrounds',
      },
      {
        type: 'contains',
        description: '30 fonts (+ import custom fonts)',
      },
      {
        type: 'contains',
        description: 'Resize canvas (Custom size)',
      },
      {
        type: 'contains',
        description: 'Import Images (Drag-and-drop)',
      },
      {
        type: 'contains',
        description: 'JPG, PNG, SVG, PDF, Animated GIF (Export)',
      },
      {
        type: 'contains',
        description: 'Presentation mode',
      },
      {
        type: 'contains',
        description: 'File backup & restore',
      },
      {
        type: 'contains',
        description: 'Use on multiple computers',
      },
      {
        type: 'contains',
        description: 'Standard support',
      },
      // {
      //   type: 'excludes',
      //   description: 'Get access to new shapes, images & templates',
      // },
    ],
  },
  {
    name: (
      <span
        css={css`
          position: relative;

          &:after {
            content: '';
            width: 24px;
            height: 22px;
            position: absolute;
            top: -5px;
            left: -13px;
            background: url(${crown}) no-repeat 0 0;
            background-size: contain;
          }
        `}
      >
        Lifetime
      </span>
    ),
    buttonType: 'solid',
    price: {
      monthly: {
        amount: 49,
        url: 'https://checkout.sketchwow.com?add-to-cart=407&cg2=1&swp=home',
      },
      lifetime: {
        amount: 49,
        url: 'https://checkout.sketchwow.com?add-to-cart=407&cg2=1&swp=home',
      },
    },
    featuresStyles: css`
      margin-top: 50px;
      @media screen and (max-width: 1020px) {
        margin-top: 35px;
      }
    `,
    priceType:"lifetime",
    features: [
      // {
      //   type: 'contains',
      //   description: 'Up to 40 elements per document',
      // },
      {
        type: 'contains',
        description: 'Up to 10 pages per document',
      },
      {
        type: 'contains',
        description: 'Unlimited sketches',
      },
      {
        type: 'contains',
        description: 'AI-powered mindmaps, flows, visuals',
      },
      {
        type: 'contains',
        description: '73 templates',
      },
      {
        type: 'contains',
        description: '350 elements, shapes, icons',
      },
      {
        type: 'contains',
        description: 'Standard color backgrounds',
      },
      {
        type: 'contains',
        description: '30 fonts (+ import custom fonts)',
      },
      {
        type: 'contains',
        description: 'Resize canvas (Custom size)',
      },
      {
        type: 'contains',
        description: 'Import Images (Drag-and-drop)',
      },
      {
        type: 'contains',
        description: 'JPG, PNG, SVG, PDF, Animated GIF (Export)',
      },
      {
        type: 'contains',
        description: 'Presentation mode',
      },
      {
        type: 'contains',
        description: 'File backup & restore',
      },
      {
        type: 'contains',
        description: 'Use on multiple computers',
      },
      {
        type: 'contains',
        description: 'Standard support',
      },
      // {
      //   type: 'excludes',
      //   description: 'Get access to new shapes, images & templates',
      // },
    ],
  },
];

const SUBTITLE_BY_TYPE: Record<Price, string> = {
  lifetime: '(One-time Payment)',
  monthly: '(Billed Monthly)',
};

export const PricingMix = (): ReactElement => {
  const memoizedData = useMemo(
    () =>
      DATA.map((item) => ({
        ...item,
        id: uniqueId(),
        features: item.features.map((feature) => ({
          ...feature,
          id: uniqueId(),
        })),
      })),
    [],
  );

  const pricingRef = useRef(null);
  const stickyDetectionRef = useRef(null);
  
  const { isVisible } = useVisibilityDetection(pricingRef, [0.1, 0.9]);
  const { isVisible: shouldHideTopBar } = useVisibilityDetection(stickyDetectionRef, [0, 1], true);

  const [priceType, setPriceType] = useState<Price>('monthly');

  return (
    <Styled.Wrapper isVisible={isVisible} ref={pricingRef} id="pricing">


      <Styled.ListWrapper>
        <Styled.ListTopBackground 
          css={css`
          max-width: 960px;
        `}
        />
        <Styled.List isMixPricing={true}>
          {memoizedData.map(({ buttonType, featuresStyles, features, id, name, price, priceType }) => (
            <Styled.PricingColumn key={id}>
              <Styled.Heading>
                <Styled.Title>{name}</Styled.Title>
                <Styled.SubTitle>{SUBTITLE_BY_TYPE[priceType]}</Styled.SubTitle>
                <Styled.Price>
                  <Styled.PriceVault>$</Styled.PriceVault>
                  <Styled.PriceAmount>{price[priceType].amount}</Styled.PriceAmount>
                  {priceType != 'lifetime' && <Styled.PricePeriod>/mo</Styled.PricePeriod>}
                </Styled.Price>
                <Styled.Button href={/*"/lifetime-plans"*/price[priceType].url} type={buttonType}>
                  Get Started
                </Styled.Button>
              </Styled.Heading>
              <Styled.Features css={featuresStyles}>
                {features.map(({ description, id: featureId, type }) => (
                  <Styled.Feature key={featureId} type={type}>
                    {description}
                  </Styled.Feature>
                ))}
              </Styled.Features>
            </Styled.PricingColumn>
          ))}
        </Styled.List>
        <Styled.ListBottomBackground 
          css={css`
          max-width: 960px;
        `}
        />
      </Styled.ListWrapper>

      <Styled.BackgroundWrapper>
        <Styled.Background>
          <Styled.BackgroundIllustrations />
        </Styled.Background>
      </Styled.BackgroundWrapper>



    </Styled.Wrapper>
  );
};
